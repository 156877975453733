.page_container,
.header_container {
    margin-left: 220px;
    transition: all .5s;
}

.table-responsive::-webkit-scrollbar {
    height: 5px;
}

.table-responsive th {
    white-space: nowrap;
}

.page_container {
    background: #F5F6FA;
    /* height: 100vh; */
    /* padding-bottom: 2rem; */
    padding: 10px;
}

.page_title {
    color: #202224;
    font-size: 18px;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 15px;
}

.text-decoration-none {
    text-decoration: unset !important;
}


::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #265936;
    border-radius: 0px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #265936;
    background-color: #555;
}

.close_sidemenu {
    display: none;
}


.show_mobile {
    display: none;
}

.filter_close {
    fill: #9e7c0c;
    font-size: 28px;
}

@media only screen and (max-width: 1024px) {
    .main_sidemenu {
        /* display: none; */
        left: -225px;
    }

    .close_sidemenu {
        display: block;
    }

    .close_sidemenu svg {
        fill: #fff;
        font-size: 23px;
    }

    .nav_close {
        fill: #ffffff;
        font-size: 25px;
    }

    .sidebar_open .main_sidemenu {
        width: 270px !important;
        display: block !important;
        z-index: 9;
        height: 100vh;
        position: fixed;
        z-index: 99;
        left: 0;
    }

    .sidebar_open::after {
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #202224bd;
        z-index: 9;
        position: fixed;
    }

    .sidebar_open .main_menu_name {
        display: block;
    }

    .sidebar_open .page_container,
    .sidebar_open .header_container {
        margin-left: 0px;
    }

    .sidebar_open .sidemenu_logo img {
        padding: 20px 20px 10px 20px;
        width: 70%;
        object-fit: initial;
        height: auto;
        object-position: initial;
    }

    .sidebar_open .sidemenu_logo {
        text-align: left !important;
    }

    .sidebar_open .close_sidemenu {
        /* display: block; */

    }

    .header_container {
        margin-left: 0px;
        padding-left: 15px;
        padding-right: 3px;
        position: sticky;
        top: 0;
        z-index: 9;
    }

    .user_name {
        font-size: 12px;
    }

    .name_filters input {
        font-size: 12px;
    }

    .users_img {
        width: 28px;
    }

    .page_container {
        margin-left: 0px;
    }
}

@media screen and (max-width:420px) {
    .chart_card {
        /* padding: 0 !important; */
    }

    .chart_card canvas {
        width: 100% !important;
    }

    .link_control {
        width: 100px;
    }
}

@media screen and (max-width:767px) {
    .filter_container .filter_col {
        padding: 8px 10px 8px 10px;
    }

    .error_message {
        font-size: 10px;
        bottom: 2px;
    }

    .input_from input {
        margin-bottom: 19px;
    }

    .name_filter input {
        width: 100%;
    }

    .login_header-menu.register {display: none;}

    .filter_container.open form.meeting_form {
        flex-direction: column;
    }

    .custom_datatable tbody tr td,
    .custom_datatable thead tr th {
        font-size: 12px;
        white-space: nowrap;
    }

    .hide_mobile {
        display: none;
    }

    .add_user-btn {
        white-space: nowrap;
    }

    .show_mobile {
        display: block;
    }

    .show_mobile .add_user-btn {
        padding: 4px 10px !important;
        font-size: 12px;
    }

    .sm_flex-end {
        justify-content: end;
    }

    .table_page-count,
    .radios_label,
    .glitch_title,
    .profile_form label,
    .liked_videos-tab li a,
    .live_video-section,
    .video_date,
    .meet_schedule,
    .meet_description p,
    .meet_media li a {
        font-size: 12px;
    }

    .table_nav {
        margin-top: 6px;
    }

    .page_title,
    .live_video-title,
    .previous_video-card h3,
    .meet_title,
    .meet_description-title {
        font-size: 16px;
    }

    .chart_card h3,
    .profile_tab li .nav-link,
    .form_save-btn,
    .password_title,
    .video_title {
        font-size: 14px !important;
    }

    .add_user-btn {
        font-size: 12px;
        padding: 5px 10px;
    }

    .profile_card {
        padding: 10px 10px;
    }

    .edit_profile-img {
        margin: 0 auto;
    }

    .meet_image img {
        height: auto;
        border-radius: 20px;
    }

    .meet_like {
        font-size: 10px;
    }

    .meeting_card {
        padding: 12px 12px;
    }
}