.have_login{
    color: #265936;
    font-size: 14px;
}
.have_login a{
    color: #9E7C0C;
    text-decoration: unset;
}

.w-85
{
    width:85%;
}
.main_body
{
    background-color: #fff;
    width:100%;
    height:100vh;
}



@media screen and (max-width:767px) {


    .login_title {
        /* margin-top: 15px !important; */
    }
    .main_body.mobile_login {
        /* overflow-x: hidden;
        overflow-y: hidden; */
    }
    .login_bg.mobile_bg
    {
        margin-top: -50px;
        margin-left: -20px;
    }
    .login_submit
    {
        margin-bottom: 0px;
    }
    .w-85.mobi_bg
    {
        width:110%;
    }
    .t_small_none
    {
        display: none !important;
    }
    .login_header-menu
    {
        margin-top: 0px !important;
    }
    .login_sm-title{
        margin-bottom: 18px;
    }
    .login_header-menu ul li a, .lang_select, .have_login, .copy_right{
        font-size: 12px;
    }
    .login_submit {
        font-size: 14px !important;
    }
}

.error_message{
    font-size: 13px;
    position: absolute;
    left: 0;
    bottom: 9px;
    color: red;
}