.custom_datatable thead tr th {
    background-color: #FAF1D4;
    color: #265936;
    font-size: 14px;
    font-weight: 800;
}

.custom_datatable tbody tr td,
.custom_datatable tbody tr {
    background-color: #fff !important;
}

.custom_datatable tbody tr td {
    font-size: 14px;
    color: #202224;
    vertical-align: middle;
    padding: 12px 8px;
}

.main_datatable .dt-paging.paging_simple {
    margin-top: 10px;
}

.main_datatable .dt-paging.paging_simple ul li .page-link {
    height: 30px;
    width: 40px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}

.main_datatable .page-item .page-link {
    box-shadow: unset !important;
}

.filter_container {
    display: flex;
    justify-content: space-between;
    background: #fff;
    margin-bottom: 0px;
    align-items: stretch;
    border-radius: 12px;
    /* gap: 20px; */
    border: 1px solid #D5D5D5;
    z-index: 6;
}

.filter_container .filter_col {
    width: 100%;
    position: relative;
    /* padding: 12px 8px; */
    padding: 8px 20px 8px 10px;
    font-size: 14px;
    color: #202224;
}

.filter_container .filter_col:not(:last-child)::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    box-shadow: -0.1px 0px 0px 0.2px #979797;
    height: 100%;
}

.filter_icon {
    width: 450px !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter_col select,
.filter_col input {
    border: unset;
    background: transparent;
    box-shadow: unset !important;
    cursor: pointer;
    font-size: 14px;
}

.reset_filter {
    color: #EA0234 !important;
    cursor: pointer;
}

.filter_col select,
.filter_col input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../assets/images/select_arrow.png');
    /* Replace 'arrow-icon.png' with the path to your arrow icon */
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 20px !important;
    /* Adjust as needed to make room for the arrow */
}

.datepicker .table-condensed {
    width: 310px;
}

.datepicker .table-condensed thead tr:nth-child(2) th {
    border-bottom: 1px solid #ddd;
    padding: 12px 0px;
    position: relative;
}

.datepicker .table-condensed thead tr th {
    text-align: center;
    cursor: pointer;
}

.datepicker .datepicker-days .active.day {
    background: #265936 !important;
}

.datepicker tbody tr td .month.focused,
.datepicker tbody tr td .month.focused.active {
    background: #265936 !important;
    color: #fff !important;
}

.datepicker tbody tr td .month.active {
    background: #EEEEEE !important;
    color: #202224 !important;
}

.datepicker .table-condensed tbody tr td {
    width: 30px;
    height: 35px;
    font-size: 14px;
}

.datepicker {
    border-radius: 22px !important;
    box-shadow: 0px 5px 40px #a9a9a963;
}

.datepicker-dropdown.datepicker-orient-bottom:after,
.datepicker-dropdown:before {
    display: none !important;
}

.datepicker .table-condensed thead tr th.dow {
    width: 30px;
    height: 35px;
    font-size: 14px;
}

.datepicker .table-condensed thead tr th.prev:hover,
.datepicker .table-condensed thead tr th.next:hover {
    background-color: transparent !important;
}

.datepicker .table-condensed thead tr:nth-child(2) th.prev::before {
    content: "";
    background: url("../../assets/images/arrow.svg");
    position: absolute;
    left: 0px;
    top: 10px;
    width: 25px;
    height: 25px;
    background-size: 8px;
    background-repeat: no-repeat;
    background-color: #E7E9EE;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: 6px 6px;
}

.datepicker .table-condensed thead tr:nth-child(2) th.next::before {
    content: "";
    background: url("../../assets/images/arrow.svg");
    position: absolute;
    right: 0px;
    top: 10px;
    width: 25px;
    height: 25px;
    background-size: 8px;
    background-repeat: no-repeat;
    background-color: #E7E9EE;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: 6px 6px;
    transform: rotate(180deg);
}

.custom_btn-success,
.custom_btn-success:hover {
    margin: auto;
    padding: 4px 20px;
    background: #CCF0EB;
    color: #00B69B;
    border: unset;
    font-size: 12px;
    font-weight: 700;
}

.custom_btn-danger,
.custom_btn-danger:hover {
    margin: auto;
    padding: 4px 20px;
    background: #FCD7D4;
    color: #EF3826;
    border: unset;
    font-size: 12px;
    font-weight: 700;
}

.custom_btn-info,
.custom_btn-info:hover {
    margin: auto;
    padding: 4px 20px;
    background: #FFEDDD;
    color: #FFA756;
    border: unset;
    font-size: 12px;
    font-weight: 700;
}

.action_list {
    list-style: none;
    display: flex;
    gap: 10px;
    padding: 0;
    margin: 0;
}

.dt-info {
    font-size: 14px;
    color: #265936;
    font-weight: 600;
}


/* meeting details  */
.meeting_card {
    background: #fff;
    border-radius: 20px;
    padding: 20px 24px;
}

.meet_title {
    color: #265936;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 3px;
}

.meet_schedule {
    color: #604A02;
    font-size: 14px;
    margin-bottom: 15px;
}

.meet_like {
    background: #EFF9F1;
    color: #1F8A30;
    font-size: 12px;
    padding: 4px 10px;
    font-weight: 600;
    border-radius: 6px;
}

.meet_image img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 30px;
    border: 1px solid #ddd;
}

.meet_description-title {
    color: #265936;
    font-size: 20px;
    font-weight: 700;
}

.meet_description p {
    color: #565656;
    font-size: 16px;
}

.meet_media {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 10px;
    align-items: center;
}

.meet_media li a {
    color: #565656;
    font-size: 16px;
}

.share_btn {
    background-color: #FAFBFD;
    border: 1px solid #D5D5D5;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
}

.share_dropdown .share_title {
    color: #265936;
    font-size: 14px;
}

.social_list {
    display: flex;
    justify-content: space-between;
    padding: 3px 26px;
}

.social_list span {
    cursor: pointer;
}

.whatsapp_report button,
.whatsapp_report button:hover {
    background: #265936;
    color: #fff;
    border-radius: 50px;
    font-size: 12px;
    padding: 6px 16px;
}

.ws-75 {
    width: 120px;
}

body {
    background-color: #F5F6FA;
}

.mob_desk_margin {
    margin-top: 15px;
}

.radio_flex {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.radios_label {
    color: #265936;
    font-size: 14px;
    margin-left: 6px;
    font-weight: 600;
}

.cc_labels {
    margin-top: -7px;
    margin-left: 2px;
}

.accent_changes {
    accent-color: #9E7C0C;
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.meeting_table tbody tr td a {
    text-decoration: unset !important;
    color: #202224 !important;
}

.meeting_table tbody tr td a:hover {
    color: #9E7C0C !important;
}

.table_nav button {
    border: 0;
    background-color: transparent;
}

.table_nav{
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #ddd;
}

.table_nav button:first-child{
    border-right: 1px solid #ddd;
}
.table_page-count{
    color: #265936;
    font-size: 14px;
    font-weight: 600;
}



















.filter_box {
    display: none;
}

.mob_fills_icon {
    display: none;
}

@media only screen and (max-width: 768px) {
    .link_control {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 170px;
    }

    .mob_desk_margin {
        margin-top: 0px;
    }

    .filter_box {
        width: 100%;
        height: 100vh;
        background-color: #fff;
        position: fixed;
        right: 0px;
        top: 0px;
        z-index: 999;
        padding: 20px;
        display: block;
    }

    .filt_head_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .filt_content_box {
        margin-top: 20px;
    }

    .filt_label {
        font-size: 14px;
        font-weight: 600;
        color: #265936;
        margin-bottom: 5px;
    }

    .fills_control {
        font-size: 14px;
    }


    .mob_padding {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .mob_fil_align {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .mob_fills_icon {
        display: block;
    }
}