@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

* {
    font-family: "Nunito Sans", sans-serif;
}

.login_container {
    display: flex;
    width: 80%;
    margin: 0 auto;
    height: 100%;
    position: relative;
}


.login_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 14px 0;
}

.login_header-menu ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: center;
}

.lang_select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../assets/images/select_arrow.png');
    /* Replace 'arrow-icon.png' with the path to your arrow icon */
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 20px !important;
    /* Adjust as needed to make room for the arrow */
}

.login_header-menu ul li a,
.lang_select {
    text-decoration: unset;
    color: #265936;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 5px;
    border-bottom: 1px solid #265936;
}

.lang_select,
.lang_select:focus {
    border: unset !important;
    outline: unset !important;
    box-shadow: unset !important;
}

.login_right {
    position: relative;
    width: 50%;
}

.form_container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 100vh; */
    padding: 30px 0px;
}

.login_title {
    font-size: 24px;
    color: #9E7C0C;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 35px;
}

.login_sm-title {
    font-size: 16px;
    color: #6F6F6F;
    font-weight: 400;
    text-align: center;
    margin-bottom: 35px;
}

.input_from {
    /* width: 70%;
    margin: 0 auto; */
}

.input_from input {
    border: 1px solid #9E7C0C;
    border-radius: 8px;
    padding: 24px 10px 24px 18px;
    height: 0;
    margin-bottom: 28px;
    width: 100%;
}

.input_from input:focus {
    border: 1px solid #9E7C0C;
    outline: none !important;
}

.input_from input:focus-visible {
    border: 1px solid #9E7C0C;
    outline: none !important;
}

.forgot_password a {
    text-decoration: unset;
    color: #265936;
    font-size: 14px;
}

.forgot_password {
    width: 100%;
    text-align: end;
}

.login_submit {
    background-color: #265936 !important;
    border-radius: 50px !important;
    padding: 10px 3px !important;
    margin: 14px 0px;
    box-shadow: 0px 12px 21px 3px #26593626;
}

.or_sign::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background: #DFDFDF;
    left: 0;
    top: 12px;
    z-index: -1;
}

.google_btn button {
    border: 1px solid #F0EDFF !important;
    border-radius: 16px !important;
    width: 100%;
    padding: 10px 0px !important;
}

.google_btn button img {
    width: 60px;
}

.copy_right {
    font-size: 14px;
    color: #265936;
}

.title_part {
    font-size: 24px;
    color: #265936;
    font-weight: 700;
    margin-top: 20px;
}

.login_logo img {
    width: 230px;
}

.login_logo-right img {
    width: 190px;
    display: none;
    margin: auto;
}

.text_close,
.text_visible {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
}

@media screen and (max-width:767px) {

    .login_left .login_logo,
    .login_left .title_part {
        display: none;
    }

    .sm-order-2 {
        order: 2;
    }

    .login_title {
        font-size: 18px;
        margin-top: 10px;
    }

    .login_sm-title,
    .login_submit {
        font-size: 14px;
    }

    .login_logo-right img {
        display: block;
    }

    .forgot_password a {
        font-size: 12px;
    }

    .main_body.mobile_login {
        overflow-x: hidden;
        /* overflow-y: hidden; */
    }

    .main_body {
        height: 100%;
    }

    .login_bg.mobile_bg {
        margin-top: -50px;
        margin-left: -20px;
    }

    .w-85.mobi_bg {
        width: 110%;
    }

    .t_small_none {
        display: none !important;
    }

    .login_header-menu {
        /* margin-top: 25px; */
    }
    .input_from input{
        padding: 20px 10px 20px 18px;
        font-size: 12px;
    }
    .input_from input::placeholder{
        font-size: 12px;
    }
    .text_close, .text_visible{
        top: 10px;
    }
}

.w-85 {
    width: 85%;
}

.main_body {
    background-color: #fff;
    width: 100%;
    height: 100%;
}

.main_body {
    background-color: #fff;
    width: 100%;
    height: 100vh;
}