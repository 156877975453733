.chart_card {
    border-radius: 14px;
}

.chart_card h3 {
    font-size: 18px;
    color: #464255;
    font-weight: 700;
}

.glitch_list ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    margin-top: 20px;
}

.glitch_list ul li {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
}

.glitch_title {
    font-size: 14px;
    color: #265936;
}

.glitch_count {
    color: #232325;
    font-size: 12px;
}

.color-1 {
    color: #FFB545;
}

.color-2 {
    color: #5A2391;
}

.color-3 {
    color: #00BEBE;
}

.color-4 {
    color: #F32D2D;
}

.color-5 {
    color: #17DF1F;
}

.color-6 {
    color: #FFB545;
}

.color-7 {
    color: #5A2391;
}

.color-8 {
    color: #00BEBE;
}


.page_title.title_wise
{
    padding-bottom: 5px;
}
.mobile_filter_icon.classes
{
    position: absolute;
    z-index: 9;
    top: 20px;
    right: 22px;
}

.filter_box {
    display: none;
}

.mob_fills_icon {
    display: none;
}

@media only screen and (max-width: 768px) {
    .filter_box {
        width: 100%;
        height: 100vh;
        background-color: #fff;
        position: fixed;
        right: 0px;
        top: 0px;
        z-index: 999;
        padding: 20px;
        display: block;
    }

    .filt_head_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .filt_content_box {
        margin-top: 20px;
    }

    .filt_label {
        font-size: 14px;
        font-weight: 600;
        color: #265936;
        margin-bottom: 5px;
    }

    .fills_control {
        font-size: 14px;
    }

    .mob_padding {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .mob_fil_align {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .mob_fills_icon {
        display: block;
    }
}