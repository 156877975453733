.profile_card {
    background: #fff;
    border-radius: 20px;
    padding: 20px 24px;
}

.profile_tab li .nav-link.active {
    border: 0;
    border-bottom: 3px solid #9E7C0C;
    color: #9E7C0C;
}

.profile_tab li .nav-link {
    border: 0;
    border-bottom: 3px solid transparent;
    color: #265936;
}

body {
    background-color: #F5F6FA;
}

.profile_form label {
    color: #232323;
    font-size: 14px;
}

.profile_form input,
.profile_form select {
    border: 1px solid #DFEAF2;
    box-shadow: unset !important;
    font-size: 14px;
    padding: 13px 14px;
    border-radius: 15px;
}

.profile_form input:focus,
.profile_form select:focus {
    border: 1px solid #9E7C0C;
}

.profile_form input::placeholder {
    font-size: 14px;
}

.form_save-btn,
.form_save-btn:hover {
    background-color: #265936;
    color: #fff;
    padding: 8px 46px;
    font-size: 16px;
}

.form_cancel-btn,
.form_cancel-btn:hover {
    background-color: #4a4a4a82;
    color: #fff;
    padding: 8px 46px;
    font-size: 16px;
}

.edit_profile-img {
    width: 130px;
    height: 130px;
}

.edit_profile-img img {
    object-fit: cover;
    width: 130px;
    height: 130px;
    border-radius: 120px;
    border: 1px solid #ddd;
}

.password_title {
    font-size: 16px;
    color: #333B69;
}

.edit_profile-img .img_edit {
    position: absolute;
    right: 6px;
    bottom: 0px;
    width: 30px;
    height: 30px;
    background: #265936;
    color: #fff;
    border-radius: 50px;
    cursor: pointer;
    border: 2px solid #ffffff;
    text-align: center;
    padding: 0px;
}

.edit_profile-img .img_edit input {
    opacity: 0;
    width: 20px;
    height: 30px;
    cursor: pointer;
    position: absolute;
    left: 0;
}
.change_password input{
    padding: 24px 14px !important;
}
.input_from-password .error_message{
    bottom: -20px;
}