.header_container {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 30px;
    border-left: 1px solid #F5F6FA;
    background-color: #fff;
    transition: all .5s;
    position: sticky;
    top: 0;
    z-index: 99;
    box-shadow: 7px 3px 11px #ddd;
}

.name_filters input {
    background-color: #F5F6FA;
    border-radius: 50px;
    border: 1px solid #D5D5D5;
    background-image: unset;
    padding: 8px 12px 8px 30px;
    font-size: 14px;
}
.name_filters {
    width: 200px;
}

.name_filters input:focus {
    border: 1px solid #D5D5D5;
}

.name_filters span {
    position: absolute;
    left: 10px;
    top: 5px;
}

.search_with_bar {
    display: flex;
    align-items: center;
}

.searces_bar {
    margin-left: 15px;
}

.main_header_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.users_img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border: 1px solid #214f33;
    border-radius: 50px;
    object-fit: cover;
}

.user_name {
    color: #265936;
    font-weight: 600;
    padding-right: 5px;
}

.user_button_customs {
    background-color: transparent !important;
    border: 0px solid transparent;
}

.inner_pages_list {
    display: flex;
    padding: 0;
    align-items: center;
    border-radius: 50px;
}

.dropdown-toggle::after {
    border-top: 0.3em solid #265936;
}

.cp {
    cursor: pointer;
}

.name_filters input:focus {
    border: 1px solid #D5D5D5;
    outline: none !important;
}

.name_filters input:focus-visible {
    border: 1px solid #D5D5D5;
    outline: none !important;
}

.sidenav_icon {
    cursor: pointer;
}

.searces_bar ul {
    position: absolute;
    background: #fff;
    width: 100%;
    padding: 10px 0px;
    margin: 0;
    list-style-type: none;
    border-radius: 8px;
    border: 1px solid #ddd;
    z-index: 9;
    left: 0;
    top: 44px;
}

.searces_bar ul li a {
    text-decoration: none;
    font-size: 13px;
    color: #464255;
    padding: 3px 10px;
    display: block;
    text-transform: capitalize;
}




.sidenav_icon.mobile {
    display: none;
}
.no_data{
    font-size: 14px;
}


@media only screen and (max-width: 1024px) {
    .sidenav_icon.mobile {
        display: block;
    }

    .sidenav_icon.desktop {
        display: none;
    }
}

@media screen and (max-width:420px) {
    .user_name {
        display: none;
    }

    .users_img {
        margin: 0;
    }

    .inner_pages_list {
        padding: 0 !important;
    }
}

