.live_video-title {
    color: #265936;
    font-size: 18px;
    font-weight: 700;
}

.live_video-section {
    color: #604A02;
    font-size: 14px;
}

.live_video-banner img {
    width: 100%;
    border-radius: 20px;
    height: 360px;
    object-fit: cover;
}

.video_status {
    color: #FF003D;
    font-size: 16px;
    font-weight: 700;
}

.upcoming_session-card {
    /* height: calc(100% - 40%); */
    overflow-y: auto;
}

.previous_video-card {
    background: #fff;
    border-radius: 20px;
    padding: 20px 20px;
    position: relative;
    overflow: hidden;
}

.previous_video-card::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    height: 10px;
    background: #FDF5F0;
    box-shadow: 1px 1px 140px 210px #FDF5F0;
    border-radius: 100px;
    z-index: 0;
}

.previous_video-card h3 {
    color: #265936;
    font-size: 20px;
    font-weight: 600;
}

.previous_video-list .video_blog-card {
    /* border: 1px solid #F36921; */
    border: 1px solid #fff5f0;
}

.previous_video-list .video_banner img {
    height: 120px;
    box-shadow: 0px 0px 10px 5px #3e4e6f29;
}

.previous_video-list {
    margin-top: -120px;
}

.previous_video-list .swiper_card {
    padding-top: 8rem;
}

.swiper_card .swiper-button-next {
    position: absolute;
    top: 20px;
}

.swiper_card .swiper-button-prev {
    position: absolute;
    top: 20px;
    right: 40px;
    left: auto;
}

.swiper_card .swiper-button-next::after,
.swiper_card .swiper-button-prev::after {
    font-size: 16px;
    color: #2E3271;
}

.previous_video-play {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.previous_video-play span {
    width: 40px;
    height: 40px;
    background: #7c8db5cc;
    border-radius: 50px;
    text-align: center;
    padding: 7px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

body {
    background-color: #F5F6FA;
}
.z-index-3{
    z-index: 3;
}
@media screen and (max-width:767px) {
    .upcoming_session-card {
        overflow-y: unset;
        overflow-x: auto;
        display: flex;
        height: auto !important;
        gap: 10px;
    }

    .upcoming_session-card .video_blog-card {
        min-width: 250px;
    }

    .upcoming_session-card::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

    .live_video-banner img {
        height: auto;
    }

    .video_description {
        flex-direction: column;
        align-items: flex-start !important;
        gap: 10px;
    }
    .previous_video-card h3{
        position: relative;
        width: 60%;
    }
}