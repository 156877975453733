.name_filter input {
    background-color: #F5F6FA;
    border-radius: 50px;
    border: 1px solid #D5D5D5;
    background-image: unset;
    padding: 8px 12px 8px 30px;
    cursor: auto;
}

.name_filter span {
    position: absolute;
    left: 10px;
    top: 8px;
}

.white-space-nowrap {
    white-space: nowrap;
}

.add_user-btn,
.add_user-btn:hover {
    background-color: #fff;
    border: 1px solid #265936;
    font-size: 13px;
    padding: 10px 28px;
}

body {
    background-color: #F5F6FA;
}

.ws-75 {
    width: 120px;
}

.texts-end {
    text-align: right;
}

.error-danger {
    color: red;
    font-size: 14px;
    margin-bottom: 0px;
    margin-top: 4px;
}

.ft_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fss_20 {
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
}

.fss_21 {
    font-size: 16px;
    cursor: pointer;
}


@media only screen and (max-width: 768px) {
    .texts-end {
        text-align: center;
    }

    .texts-end .form_cancel-btn {
        width: 100%;
        margin-bottom: 5px;
    }

    .texts-end .form_save-btn {
        width: 100%;
        margin-bottom: 5px;
    }
}












.filter_box {
    display: none;
}

.mob_fills_icon {
    display: none;
}

@media only screen and (max-width: 768px) {
    .filter_box {
        width: 320px;
        height: 100vh;
        background-color: #fff;
        position: fixed;
        right: 0px;
        top: 0px;
        z-index: 999;
        padding: 20px;
        display: block;
    }

    .filt_head_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .filt_content_box {
        margin-top: 20px;
    }

    .filt_label {
        font-size: 14px;
        font-weight: 600;
        color: #265936;
        margin-bottom: 5px;
    }

    .fills_control {
        font-size: 14px;
    }

    .desktop_filter .filter_container {
        width: 290px;
        position: fixed;
        top: 0;
        left: -130%;
        height: 100vh;
        justify-content: flex-start;
        flex-direction: column;
        z-index: 99;
        border-radius: 0;
    }

    .filter_container.open {
        left: 0;
    }

    .filter_col select,
    .filter_col input {
        /* border: 1px solid #ddd !important; */
        border-bottom: 1px solid #ddd !important;
        border-radius: 0;
    }

    .filter_col select,
    .filter_col input {
        background-position: 96% 13px !important;
    }

    .filter_icon {
        width: auto !important;
    }

    .mob_padding {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .mob_fil_align {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .mob_fills_icon {
        display: block;
    }
}